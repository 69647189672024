import React, { Fragment, useEffect, useState } from "react";

import { ApolloQueryResult } from "@apollo/client";
import { Box, TextField } from "@mui/material";
import toast from "react-hot-toast";

import FormModal from "shared/components/modals/FormModal";
import { update } from "shared/generated/clients/fleet.client";
import { GetFleetQuery } from "shared/generated/graphql";

const EditTeamModal = (props: {
  team: GetFleetQuery["fleets_by_pk"];
  open: boolean;
  onClose: () => void;
  loading: boolean;
  refetchFleet: () => Promise<ApolloQueryResult<GetFleetQuery>>;
}) => {
  const [teamName, setTeamName] = useState<string | null>(
    props.team?.name ?? null
  );
  const handleSave = async () => {
    if (!props.team?.id || !teamName) return;
    const res = await update({
      params: { path: { fleetId: props.team?.id } },
      body: { name: teamName },
    });

    if (res.error) {
      toast.error(res.error.message ?? "Could not update team name.");
      return;
    }

    await props.refetchFleet();
    props.onClose();
  };

  useEffect(() => {
    if (!props.team?.name) return;
    setTeamName(props.team?.name);
  }, [props.team]);

  return (
    <FormModal
      open={props.open}
      title="RENAME TEAM"
      subtitle="Change the name of the Team."
      onSubmit={handleSave}
      icon={<></>}
      handleClose={props.onClose}
      loading={props.loading}
      isValid={true}
      submitButtonText="Save"
      content={
        <Box px={5} pb={5}>
          <Box mt={1}>
            <TextField
              autoFocus={true}
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              id="input-teamName"
              label={"Team Name"}
              fullWidth
            />
          </Box>
        </Box>
      }
    />
  );
};

export default EditTeamModal;
